import * as React from 'react';

import { CheckIcon } from 'components/icons';

import { Wrapper, Item, Checkmark, Description } from './automate-benefits.styles';

export const Benefits = ({ items }) => (
  <Wrapper>
    {items.map((item) => (
      <Item key={item}>
        <Checkmark>
          <CheckIcon />
        </Checkmark>
        <Description>{item}</Description>
      </Item>
    ))}
  </Wrapper>
);
