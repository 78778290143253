import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';

import { getFluidImage } from 'utils';

import { IllustrationView } from './automate-illustration.view';

export const Illustration = () => {
  const { codeExample } = useStaticQuery(graphql`
    query {
      codeExample: file(relativePath: { eq: "blocks/automate/automate-illustration.png" }) {
        childImageSharp {
          fluid(maxWidth: 650) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  return <IllustrationView image={getFluidImage(codeExample)} />;
};
