import * as React from 'react';

import { Grid, GridCell } from 'components/shared/grid';

import { getTextWithoutParagraph } from '../../../../utils';
import { Stripes } from '../../../shared/stripes';

import Icon2 from './icons/goodwill-apps.svg';
import Icon1 from './icons/goodwill-box.svg';
import Icon3 from './icons/goodwill-phone.svg';
import {
  Wrapper,
  Container,
  Title,
  Item,
  ItemTitle,
  ItemText,
  Note,
  ImageCaption,
  Image,
} from './solution-3-section-2.styles';

const icons = [Icon1, Icon2, Icon3];

export const Section2 = (props) => {
  const { title, items, note, imageCaption } = props;

  return (
    <Wrapper>
      <Container>
        <Title as="h2" size="xl">
          {title}
        </Title>
        <Grid columns={2} gap={100} media={{ sm: { columns: 1 } }}>
          <GridCell>
            {items.map((item, index) => {
              const Icon = icons[index];

              return (
                <Item key={item.title}>
                  <Icon />
                  <ItemTitle>{item.title}</ItemTitle>
                  <ItemText>{item.text}</ItemText>
                </Item>
              );
            })}
            <Note key="note">{note}</Note>
          </GridCell>
          <GridCell>
            <Image />
            <ImageCaption
              dangerouslySetInnerHTML={{ __html: getTextWithoutParagraph(imageCaption) }}
            />
          </GridCell>
        </Grid>
      </Container>
      <Stripes
        layout={3}
        theme="lightgrey"
        from="#fbfbfe"
        style={{ background: 'radial-gradient(circle at 39% -330%,#3e235e,#211635 54%,#0e0d1a)' }}
      />
    </Wrapper>
  );
};
