import { Link } from 'gatsby';
import styled, { css } from 'styled-components';

import { color, transition, media } from 'components/_settings/variables';
import { ArrowRightIcon } from 'components/icons';
import { Button } from 'components/shared/button';
import { Container } from 'components/shared/container';
import { Title } from 'components/shared/title';

import { DottedLine } from './dotted-line';
import { LogosBlock } from './logos-block';

export const SellGiftCardsLinkArrowIcon = styled(ArrowRightIcon)`
  g > g {
    stroke: #a6e0ff;
    transition: ${transition.base};
  }
`;

export const SellGiftCardsLinkText = styled.span`
  margin-right: 20px;
`;

export const SellGiftCardsLink = styled(Link)`
  display: inline-flex;
  align-items: center;
  font-size: 15px;
  font-weight: 700;
  text-decoration: none;
  color: #59b8cd;
  background-color: transparent;
  border: none;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  margin-bottom: 50px;
  cursor: pointer;
  outline: none;
  transition: ${transition.base};

  ${media.sm} {
    margin-bottom: 30px;
  }

  &:hover {
    color: ${color.primary};

    ${SellGiftCardsLinkArrowIcon} {
      g > g {
        stroke: ${color.primary};
      }
    }
  }
`;

export const StyledButton = styled(Button)`
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Subtitle = styled(Title)`
  background-image: linear-gradient(95deg, #d2fbff, #8af5ff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 30px;

  &:first-child {
    margin-top: 15px;

    ${media.sm} {
      margin-top: 0;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const StyledDottedLine = styled(DottedLine)`
  margin-bottom: 50px;

  ${media.sm} {
    margin-bottom: 30px;
  }

  ${(p) => css`
    ${p.smVisible &&
    css`
      display: none;

      ${media.sm} {
        display: block;
      }
    `}

    ${p.smHidden &&
    css`
      ${media.sm} {
        display: none;
      }
    `}
  `}
`;

export const StyledLogosBlock = styled(LogosBlock)`
  ${media.sm} {
    margin-top: 20px;
  }

  ${(p) => css`
    ${p.smVisible &&
    css`
      display: none;

      ${media.sm} {
        display: block;
      }
    `}

    ${p.smHidden &&
    css`
      ${media.sm} {
        display: none;
      }
    `}
  `}
`;

export const StyledTitle = styled(Title)`
  font-size: 48px;
  background-image: linear-gradient(95deg, #d2fbff, #8af5ff);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 50px;

  ${media.sm} {
    font-size: 28px;
    margin-bottom: 30px;
  }
`;

export const Side = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-basis: calc(50% - 50px);
  max-width: calc(50% - 50px);
  overflow: hidden;

  ${media.sm} {
    max-width: 100%;
  }

  &:first-child {
    margin-right: 100px;

    ${media.sm} {
      margin-right: 0;
    }
  }
`;

export const Inner = styled(Container)`
  display: flex;

  ${media.sm} {
    display: block;
  }
`;

export const Wrapper = styled.section`
  position: relative;
  background-image: url('/images/blocks/get-started/background.svg');
  background-position: top center;
  background-size: cover;
  padding-top: 300px;
  padding-bottom: 100px;

  @media only screen and (min-width: 1920.98px) {
    background-image: radial-gradient(circle at 86% 139%, #090d43, #11bac5);
    padding-top: 90px;
  }

  ${media.sm} {
    padding-top: 200px;
    padding-bottom: 50px;
  }
`;
