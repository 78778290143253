import styled from 'styled-components';

import { color } from 'components/_settings/variables';

export const Dot = styled.div`
  flex-shrink: 0;
  width: 2px;
  height: 2px;
  background-color: ${color.primary};
  border-radius: 50%;
  margin-right: 5px;

  &:last-child {
    margin-right: 0;
  }
`;

export const Inner = styled.div`
  display: flex;
`;

export const Wrapper = styled.div`
  overflow: hidden;
`;
