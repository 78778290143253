import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';

import { AutomateView } from './automate.view';

export const Automate = () => {
  const {
    wpSharedBlock: {
      acf: {
        automateTitle,
        automateDescription,
        automateFeature1,
        automateFeature2,
        automateFeature3,
      },
    },
  } = useStaticQuery(
    graphql`
      query {
        wpSharedBlock(slug: { eq: "automate" }) {
          acf {
            automateTitle
            automateDescription
            automateFeature1
            automateFeature2
            automateFeature3
          }
        }
      }
    `
  );

  const benefits = [automateFeature1, automateFeature2, automateFeature3];

  return (
    <AutomateView title={automateTitle} description={automateDescription} benefits={benefits} />
  );
};
