import * as React from 'react';
import ReactModal from 'react-modal';

import { CloseButton, StyledCloseIcon } from '../../shared/modal/modal.styles';

import { Wrapper, Content, Iframe } from './video-modal.styles';

export const VideoModal = (props) => {
  const { url, onClose } = props;
  const iframeUrl = `//fast.wistia.net/embed/iframe/${url}`;

  return (
    <ReactModal isOpen onRequestClose={onClose}>
      <Wrapper>
        <Content>
          <Iframe
            src={iframeUrl}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Content>
      </Wrapper>
      <CloseButton onClick={onClose}>
        <StyledCloseIcon />
      </CloseButton>
    </ReactModal>
  );
};
