import GatsbyImage from 'gatsby-image';
import styled, { css } from 'styled-components';

import { Button } from 'components/shared/button';

import { color, media } from '../../../_settings/variables';
import { Container as SharedContainer } from '../../../shared/container';
import { Title as SharedTitle } from '../../../shared/title';

export const Wrapper = styled.div`
  background: #fff;
`;

export const Container = styled(SharedContainer)`
  padding-top: 111px;
  padding-bottom: 90px;
`;

export const Title = styled(SharedTitle)`
  margin-bottom: 38px;
  max-width: 640px;
`;

export const PreTitle = styled.div`
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 0.83px;
  color: #56556c;
  text-transform: uppercase;
  margin-bottom: 22px;
`;

export const Text = styled.div`
  font-size: 20px;
  color: #56556c;
  line-height: 1.6;
`;

export const ReviewContainer = styled.div`
  margin: 68px -40px 0;
  padding: 106px 100px 0;
  position: relative;

  ${media.sm} {
    padding: 40px 0;
    margin-right: 0;
    margin-left: 0;
  }
`;

export const Review = styled.div`
  background: #fff;
  border-radius: 8px;
  box-shadow: 14px 36px 150px -45px rgba(50, 50, 93, 0.5), 2px 2px 12px 0 rgba(26, 26, 67, 0.05);
  padding: 64px 64px 100px;
  max-width: 659px;
  margin-bottom: 77px;
  position: relative;
  z-index: 1;

  ${media.sm} {
    padding: 32px 32px 50px;
  }
`;

export const ReviewImage = styled(GatsbyImage)`
  border-radius: 8px;
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(23deg, rgba(6, 6, 6, 0.37), rgba(44, 41, 93, 0.01));
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(23deg, rgba(44, 41, 93, 0.82), rgba(44, 41, 93, 0.01));
    z-index: 1;
  }

  ${media.sm} {
    left: -24px;
    right: -24px;
  }
`;

export const ReviewLogo = styled(GatsbyImage)`
  margin-bottom: 40px;

  ${(p) => css`
    width: ${p.width}px;
    height: ${p.height}px;
  `};
`;

export const ReviewText = styled.div`
  line-height: 1.6;
  margin-bottom: 47px;
`;

export const ReviewName = styled.div`
  margin-bottom: 6px;
`;
export const ReviewJob = styled.div`
  font-weight: bold;
`;

export const About = styled.div`
  border-radius: 8px;
  box-shadow: 14px 36px 150px -45px rgba(50, 50, 93, 0.5), 2px 2px 12px 0 rgba(26, 26, 67, 0.05);
  background-color: #ffffff;
  padding: 40px 56px;
  position: relative;
  z-index: 1;
  display: flex;

  ${media.sm} {
    padding: 20px 28px;
  }
  ${media.md} {
    display: block;
  }
`;

export const AboutTitle = styled.div`
  font-size: 24px;
  line-height: 1.58;
  margin-bottom: 12px;
`;

export const AboutText = styled.div`
  color: #56556c;
  line-height: 1.6;
  max-width: 720px;
`;

export const AboutPlayVideo = styled.div`
  line-height: 1.75;
  font-size: 16px;
  color: ${color.text.secondary};
  margin-left: 56px;
  flex-shrink: 0;
  text-align: center;
  cursor: pointer;

  ${media.sm} {
    margin: 20px 0 0;
  }

  &:before {
    content: '';
    display: block;
    width: 88px;
    height: 88px;
    margin: 0 auto 27px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='88' height='88' viewBox='0 0 88 88'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle cx='44' cy='44' r='44' fill='%2340D1E7'/%3E%3Cpath fill='%23FFF' fill-rule='nonzero' d='M50 43.999a.999.999 0 0 0-.427-.819l-10-7a1 1 0 0 0-1.573.819V51a.999.999 0 0 0 1.573.819l10-7a.995.995 0 0 0 .427-.818v-.002c0 .001 0 .001 0 0z'/%3E%3C/g%3E%3C/svg%3E%0A");
  }
`;

export const StyledButton = styled(Button)`
  white-space: nowrap;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 30px;

  ${media.md} {
    display: block;
    margin-left: 0;
    margin-top: 20px;
  }
`;
