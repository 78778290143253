import * as React from 'react';

import { Wrapper, Inner, Dot } from './get-started-dotted-line.styles';

export const DottedLine = ({ className }) => (
  <Wrapper className={className}>
    <Inner>
      {
        // eslint-disable-next-line react/no-array-index-key
        new Array(100).fill('').map((value, index) => (
          <Dot key={index} />
        ))
      }
    </Inner>
  </Wrapper>
);
