import styled from 'styled-components';

import { media } from 'components/_settings/variables';

export const Wrapper = styled.div`
  position: absolute;
  top: -40px;
  right: -100px;
  width: 650px;

  ${media.lg} {
    top: 0;
    right: -50px;
    width: 525px;
  }

  ${media.md} {
    position: relative;
    right: -20px;
    max-width: 550px;
    width: auto;
    margin-right: auto;
    margin-left: auto;
  }

  ${media.xs} {
    max-width: 400px;
  }
`;
