import * as React from 'react';

// See examples folder
const layouts = [
  (from, to, theme) => ({
    height: 201,
    component: (
      <>
        <path fill={theme[1]} d="M1440 0v185.185H0z" />
        <path fill={theme[0]} d="M0 0v185.185h1440z" />
        <path fill={to} d="M0 62.111V201h1440v-36.277z" />
      </>
    ),
  }),

  (from, to, theme) => ({
    component: (
      <>
        <path fill={theme[0]} d="M0 0v129.703h938.268v-11.881z" />
        <path fill={theme[1]} d="M1440 0v198.02L0 199.557l1.019-60.943z" />
        <path fill={to} d="M0 65v135h1440v-37.898z" />
      </>
    ),
  }),

  (from, to, theme) => ({
    component: (
      <>
        <path fill={theme[0]} d="M.199 55.784L0 200h938.672v-37.898z" />
        <path fill={theme[1]} d="M1440 0v198.02l-1162.966 1.537v-10.026z" />
        <path fill={to} d="M3.989 200H1440v-97.747z" />
      </>
    ),
  }),

  (from, to, theme) => ({
    background: to,
    height: 162,
    component: (
      <>
        <path fill={theme[1]} d="M2000.15 0v160L64.15 0z" />
        <path fill={theme[0]} d="M-559.85 220V0h2100z" />
        <path fill={from} d="M-559.85 0v144l2300-144z" />
      </>
    ),
  }),

  (from, to, theme) => ({
    height: 240,
    component: (
      <>
        <path fill={theme[0]} d="M0 16v108l727 15z" />
        <path fill={theme[2]} d="M1440 170.185V0L627 171z" />
        <path fill={theme[1]} d="M1441 91l-1 148.25L0 124z" />
      </>
    ),
  }),
];

const themes = {
  lightBlue: ['#7F7ACF', '#D1CFFF'],
  blue: ['#4d488e', '#242067'],
  red: ['#cd8eaf', '#5c2140'],
  purple: ['#e8d7ff', '#9a6ed3'],
  purpleBlue: ['#8e6ac7', '#3a117a'],
  lightgrey: ['#eaeef2', '#d9dfe4'],

  blue3: ['#2c295d', '#E2FBFF', '#40d1e7'],
};

export const Stripes = (props) => {
  const { layout: layoutName, theme, from, to, style = {}, className } = props;
  const layout = layouts[layoutName](from, to, themes[theme]);
  const viewbox = `0 0 1440 ${layout.height || 200}`;

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewbox}
      style={{ background: layout.background || from, display: 'block', ...style }}
    >
      <g fill="none" fillRule="nonzero">
        {layout.component}
      </g>
    </svg>
  );
};

Stripes.defaultProps = {
  layout: 0,
  theme: 'lightBlue',
  to: 'transparent',
  from: 'transparent',
};
