import styled from 'styled-components';

import { fontSize, color, media } from 'components/_settings/variables';
import { Container } from 'components/shared/container';
import { Title } from 'components/shared/title';

import Stripes from './images/automate-stripes.svg';

export const StyledStripes = styled(Stripes)`
  position: absolute;
  bottom: -5px;
  left: 50%;
  width: 100%;
  min-width: 1200px;
  transform: translateX(-50%);

  @media only screen and (min-width: 1920.98px) {
    display: none;
  }
`;

export const Description = styled.p`
  font-size: ${fontSize.sm};
  line-height: 1.75;
  color: ${color.text.tertiary};
  margin-top: 0;
  margin-bottom: 40px;
`;

export const StyledTitle = styled(Title)`
  max-width: 350px;
  font-weight: 400;
  color: #897ed0;
  margin-bottom: 25px;

  ${media.sm} {
    max-width: 100%;
  }
`;

export const Content = styled.div`
  max-width: 540px;

  ${media.lg} {
    max-width: 450px;
  }

  ${media.md} {
    max-width: 100%;
    margin-bottom: 50px;
  }

  ${media.sm} {
    margin-bottom: 35px;
  }
`;

export const Inner = styled(Container)`
  position: relative;
`;

export const Wrapper = styled.section`
  position: relative;
  background-image: radial-gradient(circle at 39% 110%, #3e235e, #211635 54%, #0e0d1a);
  padding-top: 135px;
  padding-bottom: 300px;
  overflow: hidden;

  @media only screen and (min-width: 1920.98px) {
    padding-bottom: 175px;
  }

  ${media.lg} {
    padding-bottom: 200px;
  }

  ${media.sm} {
    padding-top: 75px;
    padding-bottom: 100px;
  }
`;
