import GatsbyImage from 'gatsby-image';
import * as React from 'react';

import { Wrapper } from './automate-illustration.styles';

export const IllustrationView = ({ image }) => (
  <Wrapper>
    <GatsbyImage fluid={image} alt="" loading="eager" />
  </Wrapper>
);
