import GatsbyImage from 'gatsby-image';
import styled, { css } from 'styled-components';

import { Container as SharedContainer } from 'components/shared/container';
import { Title as SharedTitle } from 'components/shared/title';

import { media } from '../../../_settings/variables';

import BackgroundSvg from './background.svg';
import GoodwillImageSvg from './goodwill-header-card.svg';

export const Wrapper = styled.div`
  position: relative;
  background-image: linear-gradient(200deg, #fff4f6, #ffffff);
`;

export const Container = styled(SharedContainer)`
  padding-top: 190px;
  padding-bottom: 64px;
  transform: translate3d(0, 0, 0);

  ${media.sm} {
    padding-top: 111px;
  }
`;

export const Background = styled(BackgroundSvg)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  height: 100%;

  ${media.md} {
    top: 50%;
    height: 50%;
  }
`;

export const PreTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #f0594d;
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  &:before {
    width: 32px;
    height: 32px;
    content: '';
    margin-right: 19px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Cg fill='none' fill-rule='nonzero' opacity='.9'%3E%3Crect width='32' height='32' fill='%23F7D5DC' rx='4'/%3E%3Cpath fill='%23FFF' d='M19.364 18.361c-1.12-.378-1.694-1.203-1.986-1.869 1.612-.967 2.451-3.261 2.451-5.005a4.102 4.102 0 1 0-8.205 0c0 1.745.84 4.041 2.454 5.007-.29.67-.864 1.502-1.98 1.864-1.97.638-3.893.847-3.893 4.07v1.367h15.043v-1.368c0-3.174-1.837-3.375-3.884-4.066z'/%3E%3Cpath fill='%23F0594D' d='M25.415 18.893a2.854 2.854 0 0 0-2.03-.842c-.768 0-1.488.3-2.03.841l-.022.022a2.854 2.854 0 0 0-2.051-.862c-.767 0-1.488.298-2.03.84a2.85 2.85 0 0 0-.842 2.031c0 .767.299 1.488.841 2.03l4.082 4.082 4.082-4.082a2.852 2.852 0 0 0 .841-2.03c0-.767-.298-1.488-.84-2.03z'/%3E%3C/g%3E%3C/svg%3E%0A");
    flex-shrink: 0;
  }

  ${media.sm} {
    flex-direction: column;
    align-items: flex-start;

    &:before {
      margin: 0 0 16px;
    }
  }
`;

export const Title = styled(SharedTitle)`
  margin-bottom: 24px;

  strong {
    color: #f0594d;
    font-weight: unset;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.md} {
    flex-direction: column;
  }
`;

export const ContentLeft = styled.div`
  max-width: 564px;
  margin-bottom: 108px;
`;

export const ContentRight = styled.div``;

export const Image = styled(GoodwillImageSvg)`
  width: 411px;
  max-width: 100%;

  ${media.md} {
    width: auto;
  }
`;

export const Description = styled.div`
  font-size: 19px;
  color: #56556c;
  line-height: 1.47;
  margin-bottom: 24px;
`;

export const Brands = styled.div`
  text-align: center;

  ${media.sm} {
    display: none;
  }
`;

export const BrandsText = styled.div`
  font-size: 24px;
  opacity: 0.5;
  margin-bottom: 32px;
`;

export const BrandLogos = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -35px;
`;

export const BrandLogo = styled(GatsbyImage)`
  display: inline-block;
  margin: 35px;
  ${(p) => css`
    width: ${p.width}px;
    height: ${p.height}px;
  `}
`;
