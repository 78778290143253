import GatsbyImage from 'gatsby-image';
import * as React from 'react';

import { getFluidImage } from 'utils';

import { Wrapper, Inner, LogoWrapper } from './get-started-logos-block.styles';

export const LogosBlock = ({ className, items }) => (
  <Wrapper className={className}>
    <Inner>
      {items.map(({ id, altText, localFile }) => {
        const fluid = getFluidImage(localFile);

        return (
          <LogoWrapper key={id}>
            <GatsbyImage
              style={{
                width: fluid.presentationWidth / 2,
                height: fluid.presentationHeight / 2,
              }}
              fluid={fluid}
              alt={altText}
              loading="eager"
            />
          </LogoWrapper>
        );
      })}
    </Inner>
  </Wrapper>
);
