import styled from 'styled-components';

import { fontSize, color, media } from 'components/_settings/variables';

export const Checkmark = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: #492b70;
  border-radius: 50%;
  margin-bottom: 20px;

  ${media.xs} {
    margin-right: 15px;
    margin-bottom: 0;
  }
`;

export const Description = styled.p`
  font-size: ${fontSize.sm};
  line-height: 1.5;
  color: ${color.text.tertiary};
  margin-top: 0;
  margin-bottom: 0;
`;

export const Item = styled.li`
  max-width: 130px;
  margin-right: 30px;

  ${media.xs} {
    display: flex;
    align-items: center;
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
  }

  &:last-child {
    ${media.xs} {
      margin-bottom: 0;
    }
  }
`;

export const Wrapper = styled.ul`
  display: flex;
  list-style-type: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;

  ${media.xs} {
    display: block;
  }
`;
