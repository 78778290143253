import * as React from 'react';

import {
  Wrapper,
  Inner,
  Content,
  StyledTitle,
  Description,
  StyledStripes,
} from './automate.styles';
import { Benefits } from './benefits';
import { Illustration } from './illustration';

export const AutomateView = ({ title, description, benefits }) => (
  <Wrapper>
    <Inner>
      <Content>
        <StyledTitle as="h2" size="xl">
          {title}
        </StyledTitle>
        <Description>{description}</Description>
        <Benefits items={benefits} />
      </Content>

      <Illustration />
    </Inner>

    <StyledStripes />
  </Wrapper>
);
