import styled from 'styled-components';

import { media } from 'components/_settings/variables';

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 72px;
  margin-right: 23px;
  margin-bottom: 10px;
  margin-left: 23px;

  ${media.md} {
    margin-right: 15px;
    margin-left: 15px;
  }
`;

export const Inner = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -23px;
  margin-bottom: -10px;
  margin-left: -23px;

  ${media.md} {
    margin-right: -15px;
    margin-left: -15px;
  }

  ${media.xs} {
    max-width: 350px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
`;
