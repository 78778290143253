import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';

import { GetStartedView } from './get-started.view';

export const GetStarted = () => {
  const {
    wpSharedBlock: {
      acf: {
        getStartedTitle,
        getStartedSection1Title,
        getStartedSection1ButtonText,
        getStartedSection1LinkText,
        getStartedSection2Title,
        getStartedSection2ButtonText,
        getStartedSection2ButtonUrl,
        getStartedLogos,
      },
    },
  } = useStaticQuery(
    graphql`
      query {
        wpSharedBlock(slug: { eq: "get-started" }) {
          acf {
            getStartedTitle
            getStartedSection1Title
            getStartedSection1ButtonText
            getStartedSection1LinkText
            getStartedSection2Title
            getStartedSection2ButtonText
            getStartedSection2ButtonUrl
            getStartedLogos {
              id
              altText
              localFile {
                childImageSharp {
                  fluid {
                    presentationWidth
                    presentationHeight
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  return (
    <GetStartedView
      title={getStartedTitle}
      section1Title={getStartedSection1Title}
      section1ButtonText={getStartedSection1ButtonText}
      section1LinkText={getStartedSection1LinkText}
      section2Title={getStartedSection2Title}
      section2ButtonText={getStartedSection2ButtonText}
      section2ButtonURL={getStartedSection2ButtonUrl}
      logos={getStartedLogos}
    />
  );
};
