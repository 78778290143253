import { graphql } from 'gatsby';
import * as React from 'react';

import { Automate } from 'components/blocks/automate';
import { Footer } from 'components/blocks/footer';
import { GetStarted } from 'components/blocks/get-started';
import { Section1 } from 'components/pages/solution-3/section-1';
import { Section2 } from 'components/pages/solution-3/section-2';
import { Section3 } from 'components/pages/solution-3/section-3';
import { Section4 } from 'components/pages/solution-3/section-4';

import Layout from '../components/_settings/layout';
import { Header } from '../components/blocks/header';
import { buildPageMetadata } from '../utils/data-builders';
import { extractItems } from '../utils/extract-items';

const Solution3 = (props) => {
  const {
    data: {
      wpPage,
      wpPage: { acf },
    },
  } = props;

  return (
    <Layout pageMetadata={buildPageMetadata(wpPage)}>
      <Header />
      <Section1
        pretitle={acf.section1Pretitle}
        title={acf.section1Title}
        description={acf.section1Description}
        buttonText={acf.section1ButtonText}
        brandsText={acf.section1BrandsText}
        brandsLogos={acf.section1BrandsLogos}
      />
      <Section2
        title={acf.section2Title}
        items={extractItems(acf, 'section2Item')}
        imageCaption={acf.section2ImageCaption}
        note={acf.section2Note}
      />
      <Automate />
      <Section3
        title={acf.section3Title}
        description={acf.section3Description}
        groups={extractItems(acf, 'section3Group')}
      />
      <Section4
        pretitle={acf.section4Pretitle}
        title={acf.section4Title}
        textLeft={acf.section4TextLeft}
        textRight={acf.section4TextRight}
        review={{
          image: acf.section4ReviewImage,
          logo: acf.section4ReviewLogo,
          text: acf.section4ReviewText,
          fullName: acf.section4ReviewFullName,
          jobTitle: acf.section4ReviewJobTitle,
        }}
        about={{
          title: acf.section4AboutTitle,
          text: acf.section4AboutText,
          videoUrl: acf.section4AboutVideoUrl,
          buttonUrl: acf.section4AboutButtonLink,
          buttonText: acf.section4AboutButtonText,
        }}
      />
      <GetStarted />
      <Footer />
    </Layout>
  );
};

export default Solution3;

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      ...wordpressPagePageMetadata
      acf {
        section1Pretitle
        section1Title
        section1Description
        section1ButtonText
        section1BrandsText
        section1BrandsLogos {
          id
          altText
          localFile {
            childImageSharp {
              fluid {
                presentationWidth
                presentationHeight
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }

        section2Title
        section2Item1Title
        section2Item1Text
        section2Item2Title
        section2Item2Text
        section2Item3Title
        section2Item3Text
        section2Note
        section2ImageCaption

        section3Title
        section3Description
        section3Group1Title
        section3Group2Title
        section3Group3Title
        section3Group1Advantage
        section3Group1Disadvantage
        section3Group2Advantage
        section3Group2Disadvantage
        section3Group3Advantage
        section3Group3Disadvantage
        section4Pretitle
        section4Title
        section4TextLeft
        section4TextRight
        section4ReviewImage {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1335) {
                presentationWidth
                presentationHeight
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        section4ReviewLogo {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 150) {
                presentationWidth
                presentationHeight
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        section4ReviewText
        section4ReviewFullName
        section4ReviewJobTitle
        section4AboutTitle
        section4AboutText
        section4AboutVideoUrl
        section4AboutButtonText
        section4AboutButtonLink {
          ... on WpPage {
            uri
          }
        }
      }
    }
  }
`;
