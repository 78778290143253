import { Link } from 'gatsby';
import * as React from 'react';

import { Grid, GridCell } from 'components/shared/grid';

import { getTextWithoutParagraph } from '../../../../utils';
import { VideoModal } from '../../../blocks/video-modal';

import {
  Wrapper,
  Container,
  Title,
  PreTitle,
  Text,
  ReviewContainer,
  Review,
  ReviewImage,
  ReviewLogo,
  ReviewText,
  ReviewName,
  ReviewJob,
  About,
  AboutTitle,
  AboutText,
  StyledButton,
  ButtonContainer,
} from './solution-3-section-4.styles';

export const Section4 = (props) => {
  const { pretitle, title, textLeft, textRight, review, about } = props;
  const [showVideo, setShowVideo] = React.useState(false);

  return (
    <Wrapper>
      <Container>
        <PreTitle>{pretitle}</PreTitle>
        <Title size="lg" as="h2">
          {title}
        </Title>
        <Grid columns={2} gap={50} media={{ sm: { columns: 1 } }}>
          <GridCell>
            <Text
              dangerouslySetInnerHTML={{
                __html: getTextWithoutParagraph(textLeft),
              }}
            />
          </GridCell>
          <GridCell>
            <Text
              dangerouslySetInnerHTML={{
                __html: getTextWithoutParagraph(textRight),
              }}
            />
          </GridCell>
        </Grid>
        <ReviewContainer>
          <ReviewImage
            width={review.image.localFile.childImageSharp.fluid.presentationWidth}
            height={review.image.localFile.childImageSharp.fluid.presentationHeight}
            fluid={review.image.localFile.childImageSharp.fluid}
            alt={review.image.altText}
          />
          <Review>
            <ReviewLogo
              width={review.logo.localFile.childImageSharp.fluid.presentationWidth}
              height={review.logo.localFile.childImageSharp.fluid.presentationHeight}
              fluid={review.logo.localFile.childImageSharp.fluid}
              alt={review.logo.altText}
            />
            <ReviewText>{review.text}</ReviewText>
            <ReviewName>{review.fullName}</ReviewName>
            <ReviewJob>{review.jobTitle}</ReviewJob>
          </Review>

          <About>
            <div>
              <AboutTitle>{about.title}</AboutTitle>
              <AboutText>{about.text}</AboutText>
            </div>
            {/* <AboutPlayVideo onClick={() => setShowVideo(true)}>Play the video</AboutPlayVideo> */}
            <ButtonContainer>
              <div>
                <StyledButton theme="bordered" component={Link} to={about.buttonUrl.uri}>
                  {about.buttonText}
                </StyledButton>
              </div>
            </ButtonContainer>
          </About>
        </ReviewContainer>
      </Container>

      {showVideo && <VideoModal url={about.videoUrl} onClose={() => setShowVideo(false)} />}
    </Wrapper>
  );
};
