import styled, { createGlobalStyle } from 'styled-components';

import { media } from '../../../_settings/variables';
import { Container as SharedContainer } from '../../../shared/container';
import { Title as SharedTitle } from '../../../shared/title';

import LogoSvg from './wegift-blue.svg';

const headerMarginBottom = 140;

const descriptionLineHeight = 28;
const descriptionPaddingTop = 16;
const descriptionHeight = descriptionLineHeight + descriptionPaddingTop;

export const Wrapper = styled.div``;

export const GlobalStyle = createGlobalStyle`body { overflow: unset }`;

export const Container = styled(SharedContainer)`
  margin-top: 195px;
  display: flex;
  position: relative; // important for title's animation

  ${media.md} {
    margin-top: 57px;
    flex-direction: column;
    margin-bottom: -50px;
  }
`;

export const ColumnLeft = styled.div`
  min-width: 374px;
  margin-right: 80px;
  position: relative;

  ${media.lg} {
    min-width: 300px;
  }

  ${media.md} {
    min-width: unset;
    margin: 0 0 60px;
  }

  ${media.sm} {
    text-align: center;
  }
`;

export const StickyHeader = styled.div`
  position: sticky;
  top: 20px;
  margin-bottom: ${headerMarginBottom}px;

  ${media.lg} {
    margin-bottom: 140px;
  }

  ${media.md} {
    margin-bottom: 0;
  }
`;

export const ColumnRight = styled.div``;

export const Logo = styled(LogoSvg)`
  margin-bottom: 16px;
`;

export const Title = styled(SharedTitle)`
  line-height: 1.52;
  font-weight: normal;
`;

export const CardTitlePlace = styled(Title)`
  color: transparent;

  ${media.lg} {
    display: none;
  }
`;

export const Description = styled.div`
  font-size: 16px;
  color: #56556c;
  line-height: ${descriptionLineHeight}px;
  padding-top: ${descriptionPaddingTop}px;
  background: #fff;
`;

export const CardsGroup = styled.div`
  display: flex;
  margin-bottom: 76px;
  position: relative;
  z-index: 2;

  ${media.sm} {
    flex-direction: column;
  }
`;

const Card = styled.div`
  border-radius: 8px;
  background: #fff;
  box-shadow: 14px 36px 150px -45px rgba(50, 50, 93, 0.5), 2px 2px 12px 0 rgba(26, 26, 67, 0.05);
`;

export const CardTitle = styled.div`
  border-bottom: 1px solid #eaeef2;
  line-height: 56px;
  text-align: center;
  text-transform: uppercase;
  color: #56556c;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
`;

export const CardItems = styled.div`
  padding: 40px;
  font-size: 16px;
  line-height: 1.75;
  color: #56556c;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  li {
    padding-left: 49px;
    position: relative;
    padding-bottom: 16px;

    &:before {
      content: '';
      position: absolute;
      width: 24px;
      height: 24px;
      top: 2px;
      left: 0;
    }
  }
`;

export const Disadvantages = styled(CardItems)`
  li {
    &:before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle cx='12' cy='12' r='12' fill='%23EAEEF2'/%3E%3Cpath fill='%23A7AEC1' d='M8 11h8v2H8z'/%3E%3C/g%3E%3C/svg%3E%0A");
    }
  }
`;

export const Advantages = styled(CardItems)`
  li {
    &:before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle cx='12' cy='12' r='12' fill='%23D5F9FF'/%3E%3Cpath fill='%2338CFE7' d='M8 11h8v2H8z'/%3E%3Cpath fill='%2338CFE7' d='M13 8v8h-2V8z'/%3E%3C/g%3E%3C/svg%3E%0A");
    }
  }
`;

export const CardItem = styled.div`
  padding-left: 49px;
  position: relative;
  padding-bottom: 16px;

  &:before {
    content: '';
    position: absolute;
    width: 24px;
    height: 24px;
    top: 2px;
    left: 0;
  }
`;

export const DisadvantagesCard = styled(Card)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  ${CardTitle} {
    color: #6b7696;
  }
`;

export const AdvantagesCard = styled(Card)`
  margin-top: -16px;
  margin-bottom: -16px;

  ${CardTitle} {
    line-height: 72px;
  }
`;

const titlesContainerBottom = headerMarginBottom + descriptionHeight;

export const TitlesContainer = styled.div`
  position: absolute;
  bottom: ${titlesContainerBottom}px;
  left: 0;
  right: 0;
  top: 159px;

  ${media.lg} {
    display: none;
  }
`;

const stickyTitleHeight = 63;

export const StickyTitle = styled(Title)`
  position: sticky;
  top: 180px;
  color: #22c9e3;
  background: #fff;
`;

export const TitleOverlay = styled.div`
  position: sticky;
  top: 242px;
  height: 63px;
  background: #fff;

  &:first-child {
    opacity: 0;
  }
`;

export const TitleOverlaysContainer = styled(TitlesContainer)`
  bottom: ${titlesContainerBottom - stickyTitleHeight}px;
  z-index: 0;
`;
