import * as React from 'react';

import { Stripes } from '../../../shared/stripes';

import {
  Wrapper,
  Container,
  GlobalStyle,
  Logo,
  Title,
  Description,
  ColumnLeft,
  ColumnRight,
  StickyHeader,
  CardsGroup,
  DisadvantagesCard,
  AdvantagesCard,
  CardTitle,
  TitlesContainer,
  StickyTitle,
  CardTitlePlace,
  TitleOverlaysContainer,
  TitleOverlay,
  Advantages,
  Disadvantages,
} from './solution-3-section-3.styles';

export const Section3 = (props) => {
  const { description, groups } = props;

  const cardTitlePlaceRef = React.createRef();
  const groupRefs = groups.map(() => ({
    titleRef: React.createRef(),
    titleOverlayRef: React.createRef(),
    cardsRef: React.createRef(),
  }));

  React.useEffect(() => {
    let occupiedHeight =
      cardTitlePlaceRef.current.offsetTop + cardTitlePlaceRef.current.offsetHeight;
    let prevMargin = 0;
    groupRefs.forEach((refs, index) => {
      if (index < 1) return;

      const cardsOffset = refs.cardsRef.current.offsetTop;

      const margin = cardsOffset - occupiedHeight + prevMargin;
      prevMargin = margin;
      occupiedHeight += refs.cardsRef.current.offsetHeight;

      refs.titleRef.current.style.marginTop = `${margin}px`;
      refs.titleOverlayRef.current.style.height = `${refs.titleRef.current.offsetHeight}px`;
      refs.titleOverlayRef.current.style.marginTop = `${margin}px`;
    });
  }, []);

  return (
    <Wrapper>
      <GlobalStyle />
      <Container>
        <ColumnLeft>
          <TitlesContainer>
            {groups.map((group, index) => (
              <StickyTitle size="lg" key={group.title} ref={groupRefs[index].titleRef}>
                {group.title}
              </StickyTitle>
            ))}
          </TitlesContainer>
          <TitleOverlaysContainer>
            {groups.map((group, index) => (
              <TitleOverlay key={group.title} ref={groupRefs[index].titleOverlayRef} />
            ))}
          </TitleOverlaysContainer>
          <StickyHeader>
            <Logo />
            <Title size="lg">The WeGift way vs.</Title>
            <CardTitlePlace size="lg" ref={cardTitlePlaceRef}>
              Stub
            </CardTitlePlace>
            <Description>{description}</Description>
          </StickyHeader>
        </ColumnLeft>
        <ColumnRight>
          {groups.map((group, index) => (
            <CardsGroup key={group.title} ref={groupRefs[index].cardsRef}>
              <DisadvantagesCard>
                <CardTitle>{group.title}</CardTitle>
                <Disadvantages dangerouslySetInnerHTML={{ __html: group.disadvantage }} />
              </DisadvantagesCard>
              <AdvantagesCard>
                <CardTitle>wegift</CardTitle>
                <Advantages dangerouslySetInnerHTML={{ __html: group.advantage }} />
              </AdvantagesCard>
            </CardsGroup>
          ))}
        </ColumnRight>
      </Container>
      <Stripes theme="blue3" layout={4} style={{ zIndex: 1, position: 'relative' }} />
    </Wrapper>
  );
};
