import { motion } from 'framer-motion';
import { Link } from 'gatsby';
import * as React from 'react';

import { RevealTextFromTop, FadeInBottom, FadeInBottomS } from 'animations';

import { getTextWithoutParagraph } from '../../../../utils';
import { Button } from '../../../shared/button';

import {
  Wrapper,
  Background,
  Container,
  PreTitle,
  Title,
  Description,
  Brands,
  BrandsText,
  Content,
  ContentLeft,
  ContentRight,
  Image,
  BrandLogos,
  BrandLogo,
} from './solution-3-section-1.styles';

const containerAnimationVariants = {
  hidden: {},
  visible: {
    transition: {
      staggerChildren: 0.1,
    },
  },
};

export const Section1 = (props) => {
  const { pretitle, title, description, brandsText, buttonText, brandsLogos } = props;

  return (
    <Wrapper>
      <Background />
      <Container>
        <Content>
          <ContentLeft>
            <motion.div initial="hidden" animate="visible" variants={containerAnimationVariants}>
              <motion.div variants={FadeInBottomS}>
                <PreTitle>{pretitle}</PreTitle>
                <Title as="h1" size="xxl">
                  {getTextWithoutParagraph(title)
                    .split('<br />')
                    .map((part, index) => (
                      <motion.div
                        key={`intro-title-${index}`}
                        variants={RevealTextFromTop}
                        dangerouslySetInnerHTML={{ __html: part }}
                      />
                    ))}
                </Title>
              </motion.div>
              <motion.div variants={FadeInBottom}>
                <Description>{description}</Description>
              </motion.div>
              <motion.div variants={FadeInBottom}>
                <Button component={Link} to="/demo/">
                  {buttonText}
                </Button>
              </motion.div>
            </motion.div>
          </ContentLeft>
          <ContentRight>
            <Image />
          </ContentRight>
        </Content>

        <Brands>
          <BrandsText>{brandsText}</BrandsText>
          <BrandLogos>
            {brandsLogos.map((logo) => (
              <BrandLogo
                width={logo.localFile.childImageSharp.fluid.presentationWidth / 2}
                height={logo.localFile.childImageSharp.fluid.presentationHeight / 2}
                key={logo.id}
                fluid={logo.localFile.childImageSharp.fluid}
                alt={logo.altText}
              />
            ))}
          </BrandLogos>
        </Brands>
      </Container>
    </Wrapper>
  );
};
