import styled from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
`;

export const Content = styled.div`
  max-width: 840px;
  transform: translateY(-50%) translateX(-50%);
  top: 50%;
  position: absolute;
  left: 50%;
  width: 100%;

  ::after {
    content: '';
    display: block;
    padding-bottom: 56.4%;
  }
`;

export const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;
