import styled from 'styled-components';

import { media } from '../../../_settings/variables';
import { Container as SharedContainer } from '../../../shared/container';
import { Title as SharedTitle } from '../../../shared/title';

import ImageSvg from './goodwill-body-card.svg';

export const Wrapper = styled.div`
  background: #fbfbfe;
  overflow: hidden;
`;

export const Container = styled(SharedContainer)`
  padding-top: 123px;
  padding-bottom: 134px;

  ${media.sm} {
    padding-top: 60px;
  }
`;

export const Title = styled(SharedTitle)`
  margin-bottom: 40px;

  ${media.sm} {
    margin-bottom: 35px;
  }
`;

export const Item = styled.div`
  padding-left: 100px;
  position: relative;

  & + & {
    margin-top: 64px;
  }

  svg {
    position: absolute;
    left: 0;
  }

  ${media.sm} {
    & + & {
      margin-top: 32px;
    }
  }
`;

export const ItemTitle = styled.div`
  font-size: 24px;
  font-weight: 500;
  line-height: 1.42;
  margin-bottom: 12px;
`;

export const ItemText = styled.div`
  font-size: 16px;
  color: #60607d;
  line-height: 1.75;
`;

export const Note = styled.div`
  color: #f0594d;
  font-size: 40px;
  margin-top: 72px;

  ${media.sm} {
    font-size: 32px;
    margin-top: 42px;
    text-align: center;
  }

  ${media.md} {
    font-size: 32px;
  }
`;

export const Image = styled(ImageSvg)`
  display: block;
  margin-top: -50px;
`;

export const ImageCaption = styled.div`
  box-shadow: -24px 24px 119px -44px rgba(50, 50, 93, 0.5);
  background-color: #ffffff;
  border-radius: 8px;
  padding: 28px;
  max-width: 428px;
  position: relative;
  font-size: 26px;
  font-weight: 500;
  text-align: center;
  margin: 88px auto 0;
  color: #56556c;

  ${media.sm} {
    font-size: 20px;
    padding: 20px;
  }

  strong {
    font-weight: 500;
    color: #f0594d;
  }

  &:before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background: #fff;
    transform: rotate(45deg);
    top: -10px;
    right: 50%;
  }
`;
