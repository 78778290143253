import * as React from 'react';

import { Link } from 'components/shared/link';

import {
  Wrapper,
  Inner,
  Side,
  StyledTitle,
  StyledDottedLine,
  StyledLogosBlock,
  Subtitle,
  StyledButton,
  SellGiftCardsLink,
  SellGiftCardsLinkText,
  SellGiftCardsLinkArrowIcon,
} from './get-started.styles';

export const GetStartedView = (props) => {
  const {
    title,
    section1Title,
    section1ButtonText,
    section1LinkText,
    section2Title,
    section2ButtonText,
    section2ButtonURL,
    logos,
  } = props;

  return (
    <Wrapper>
      <Inner>
        <Side>
          <StyledTitle as="h2">{title}</StyledTitle>
          <StyledDottedLine smHidden />
          <StyledLogosBlock items={logos} smHidden />
        </Side>
        <Side>
          <Subtitle as="h3" size="sm">
            {section1Title}
          </Subtitle>
          <StyledButton component={Link} to="/demo/">
            {section1ButtonText}
          </StyledButton>
          <SellGiftCardsLink to="/demo/">
            <SellGiftCardsLinkText>{section1LinkText}</SellGiftCardsLinkText>
            <SellGiftCardsLinkArrowIcon />
          </SellGiftCardsLink>
          <StyledDottedLine />
          <Subtitle as="h3" size="sm">
            {section2Title}
          </Subtitle>
          <StyledButton component={Link} to={section2ButtonURL}>
            {section2ButtonText}
          </StyledButton>
          <StyledLogosBlock items={logos} smVisible />
        </Side>
      </Inner>
    </Wrapper>
  );
};
